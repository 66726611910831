.model-content {
  font-family: optima_nova_lt_regular, sans-serif;
  background-color: #F5F5F5;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
  outline: 0;
  color: #303030 !important;
  text-align: left !important;
}

.modelheader {
  border-bottom: 1px solid;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  background-color: #F5F5F5;
  z-index: 9990;
}

.modelbody {
  border-bottom: 1px solid;
}

.modelfooter {
  /* font-size: 20px; */
  /* font-weight: 500; */
  /* line-height: 60px; */
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.modelfooter button {
  margin-left: 5px;
  background-color: #303030;
  color: #F5F5F5 !important;
}

.formgroup option {
  background-color: #F5F5F5;
  border: 1px solid;
}

.formgroup input {
  background-color: #F5F5F5;
  border: 1px solid;
}

.formgroup Col {
  background-color: #F5F5F5 !important;
  border: 1px solid;
}

#Select {
  background-color: #F5F5F5;
  border: 1px solid;
}

#fluorescence {
  background-color: #F5F5F5;
  border: 1px solid;
}

#message {
  background-color: #F5F5F5;
  border: 1px solid;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.arjiv-creation1 {
  position: absolute;
  top: 240px;
  left: 163px;
  font-size: 42px;
  font-weight: 700;
  color: rgb(224, 220, 217);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: left;
}

@media (max-width: 1024px) {
  .arjiv-creation1 {
    position: absolute;
    top: 100px;
    left: 90px;
    font-size: 35px;
    font-weight: 700;
    color: rgb(224, 220, 217);
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .arjiv-creation1 {
    position: absolute;
    top: 50px;
    left: 50px;
    font-size: 25px;
    font-weight: 600;
    color: rgb(224, 220, 217);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
  .bg-image-text {
    position: absolute;
    bottom: 50px;
    left: 100px;
    font-size: 18px;
    line-height: 40px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 1px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}

@media (max-width: 425px) {
  .arjiv-creation1 {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgb(224, 220, 217);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
  .bg-image-text {
    position: absolute;
    bottom: 15px;
    left: 65px;
    font-size: 17px;
    line-height: 30px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 0.5px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}

@media (max-width: 375px) {
  .bg-image-text {
    position: absolute;
    bottom: 15px;
    left: 65px;
    font-size: 17px;
    line-height: 30px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 0.5px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}
