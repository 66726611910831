/******************* Timeline Demo - 9 *****************/
.main-timeline9 {
  position: relative;
}

.main-timeline9:after,
.main-timeline9:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline9:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #F5F5F5;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-timeline9 .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}

.main-timeline9 .timeline:after,
.main-timeline9 .timeline:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline9 .timeline:first-child:before,
.main-timeline9 .timeline:last-child:before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #F5F5F5;
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
}

.main-timeline9 .timeline:last-child:before {
  top: auto;
  bottom: 0;
}

.main-timeline9 .timeline:last-child:nth-child(even):before {
  right: auto;
  left: -12px;
  bottom: -2px;
}

.main-timeline9 .timeline-content {
  text-align: center;
  margin-top: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    img {
      scale: 1.3;
    }
  }
}

.main-timeline9 .timeline-content:before {
  content: "";
  width: 100%;
  height: 5px;
  background: #F5F5F5;
  position: absolute;
  top: 88px;
  left: 0;
  z-index: -1;
}

.main-timeline9 .circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  background: #313131;
  border: 8px solid #F5F5F5;
  float: left;
  margin-right: 25px;
  margin-top: 25px;
  position: relative;
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before,
.main-timeline9 .circle:before {
  content: "";
  margin: auto;
  position: absolute;
  right: -33px;
  bottom: 0;
  z-index: -1;
}

.main-timeline9 .circle:before {
  width: 26px;
  height: 30px;
  background: #F5F5F5;
  top: 0;
  box-shadow: inset 7px 0 9px -7px #444;
}

.main-timeline9 .circle span {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 110px;
  border: 3px solid #adabab;
  font-size: 70px;
  color: #454344;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before {
  width: 28px;
  height: 50px;
  background: #303030;
  border-radius: 0 0 0 21px;
  top: -54px;
  border-color: #303030;
}

.main-timeline9 .circle span:after {
  border-radius: 21px 0 0;
  top: 0;
  bottom: -56px;
}

.main-timeline9 .content {
  display: table;
  padding-right: 40px;
  position: relative;
}

.main-timeline9 .year {
  display: block;
  padding: 10px;
  margin: 10px 0 50px;
  background: #67676b;
  border-radius: 7px;
  font-size: 25px;
  color: #fff;
}

.main-timeline9 .title {
  font-size: 25px;
  /* font-weight: 700; */
  color: #F5F5F5;
  margin-top: 0;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before,
.main-timeline9 .icon:before {
  content: "";
  height: 25px;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: -15px;
}

.main-timeline9 .description {
  font-size: 16px;
  color: #aaa;
  text-align: justify;
  margin-bottom: 20px;
  margin-top: 15px;
  letter-spacing: 0.5px;
}

.main-timeline9 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #F5F5F5;
  position: absolute;
  top: 78px;
  right: -14px;
}

.main-timeline9 .icon:before {
  width: 15px;
  background: #F5F5F5;
  top: -1px;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before {
  width: 21px;
  background: #303030;
  border-radius: 0 0 21px;
  top: -30px;
  border-color: #303030;
}

.main-timeline9 .icon span:after {
  border-radius: 0 21px 0 0;
  top: 0;
  left: -15px;
  bottom: -30px;
}

.main-timeline9 .timeline:nth-child(2n) .circle,
.main-timeline9 .timeline:nth-child(2n) .timeline-content {
  float: right;
}

.main-timeline9 .timeline:nth-child(2n) .circle {
  margin: 25px 0 0 25px;
}

.main-timeline9 .timeline:nth-child(2n) .circle:before {
  right: auto;
  left: -33px;
  box-shadow: -7px 0 9px -7px #444 inset;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after,
.main-timeline9 .timeline:nth-child(2n) .circle span:before {
  right: auto;
  left: -33px;
  border-radius: 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after {
  border-radius: 0 21px 0 0;
}

.main-timeline9 .timeline:nth-child(2n) .content {
  padding: 0 0 0 40px;
  margin-left: 2px;
}

.main-timeline9 .timeline:nth-child(2n) .icon {
  right: auto;
  left: -14px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after,
.main-timeline9 .timeline:nth-child(2n) .icon span:before,
.main-timeline9 .timeline:nth-child(2n) .icon:before {
  left: auto;
  right: -15px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:before {
  border-radius: 0 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after {
  border-radius: 21px 0 0;
}

/* .main-timeline9 .timeline:nth-child(2) {
  margin-top: 180px;
} */

.main-timeline9 .timeline:nth-child(odd) {
  margin: -135px 0 0;
}

.main-timeline9 .timeline:nth-child(even) {
  margin-top: 150px;
}

.main-timeline9 .timeline:first-child,
.main-timeline9 .timeline:last-child:nth-child(even) {
  margin: 0;
}
.timeline.tm-12 {
  margin-top: 50px;
}
.main-timeline9 .circle span img {
  width: 74px;
  /* margin-top: 8px; */
  transition: all 1s ease-in-out;
}
.main-timeline9 .timeline.tm-15 {
  margin-top: -110px !important;
}

@media only screen and (max-width: 990px) {
  .main-timeline9:before {
    left: 100%;
  }

  .main-timeline9 .timeline {
    width: 100%;
    float: none;
    margin-bottom: 20px !important;
  }

  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
    left: auto !important;
    right: -13px !important;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle {
    float: left;
    margin: 0 25px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle:before {
    right: -33px;
    left: auto;
    box-shadow: 7px 0 9px -7px #444 inset;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle span:after,
  .main-timeline9 .timeline:nth-child(2n) .circle span:before {
    right: -33px;
    left: auto;
    border-radius: 0 0 0 21px;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle span:after {
    border-radius: 21px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .content {
    padding: 0 40px 0 0;
    margin-left: 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon {
    right: -14px;
    left: auto;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:after,
  .main-timeline9 .timeline:nth-child(2n) .icon span:before,
  .main-timeline9 .timeline:nth-child(2n) .icon:before {
    left: -15px;
    right: auto;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:before {
    border-radius: 0 0 21px;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:after {
    border-radius: 0 21px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2),
  .main-timeline9 .timeline:nth-child(even),
  .main-timeline9 .timeline:nth-child(odd) {
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  .main-timeline9:before {
    left: 0;
  }

  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
    left: -12px !important;
    right: auto !important;
  }

  .main-timeline9 .circle,
  .main-timeline9 .timeline:nth-child(2n) .circle {
    width: 130px;
    height: 130px;
    float: none;
    margin: 0 auto;
  }

  .main-timeline9 .timeline-content:before {
    width: 99.5%;
    top: 68px;
    left: 0.5%;
  }

  .main-timeline9 .circle span {
    line-height: 115px;
    font-size: 60px;
  }

  .main-timeline9 .circle span:after,
  .main-timeline9 .circle span:before,
  .main-timeline9 .circle:before,
  .main-timeline9 .icon {
    display: none;
  }

  .main-timeline9 .content,
  .main-timeline9 .timeline:nth-child(2n) .content {
    padding: 0 10px 0 30px;
  }

  .main-timeline9 .year {
    margin-bottom: 15px;
  }

  .main-timeline9 .description {
    text-align: justify;
  }
}

.journery-holder {
  background: #303030;
}

.journeryTitle h3,
.sectionTitle h3 {
  font-size: 30px;
  text-align: center;
  color: #F5F5F5;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}
.journeryTitle h3 span {
  font-size: 63px;
}
.journeryTitle h6 {
  color: #F5F5F5;
  font-size: 28px;
  /* margin-top: 10px; */
  text-align: center;
  width: 100%;
}

.journeryTitle p {
  color: #F5F5F5;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

@keyframes wd-an {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
