.loading-bar {
    z-index: 100000;
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: #00000083;

    .spinner {
        --size-of-spinner: 100px;
        --spinner-border-width: 7px;
        --spinner-color: #FFF;
        --circle-color: #4b4b4b00;
        --speed-of-animation: 2s;
        width: var(--size-of-spinner);
        height: var(--size-of-spinner);
        background: var(--circle-color);
        border-radius: 50%;
        position: relative;
    }
}

.spinner::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    inset: 0;
    border: 7px solid var(--spinner-color);
    border-right-color: transparent;
    animation: spinny var(--speed-of-animation) linear infinite;
}

@keyframes spinny {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}