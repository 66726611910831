@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
h1,h2,h3,h4,h5,h6 {
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}
*{
  margin: 0;
  padding: 0;
  font-family: optima_nova_lt_regular, sans-serif;
  /* font-family: "Poppins", sans-serif; */
}
img {
  max-width: 100%;
  height: auto;
}
p{
  /* font-family: "RocknRoll One", sans-serif; */
  &:last-child {
    margin-bottom: 0;
  }
}
.row {
  margin-left: auto;
  margin-right: auto;
}
.logo-container {
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  background: #F5F5F5;
  padding: 0 0;
}

.logo img.img-fluid {
  width: 80px;
  position: absolute;
  left: 45px;
}
.logo-container p h1 {
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 50px;
  line-height: 75px;
  font-weight: 700;
}

.logo {
  width: 100%;
  position: relative;
  text-align: center;
}

/* ------------------------------------------ */
.nav-1 {
  background-color: #303030;
  color: aliceblue;
  padding: 0;
  transition: background-color 0.3s, padding 0.3s;
  background-color: transparent;
  /* height: 50px; */
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #303030 !important;
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #F5F5F5;
}

.navLink {
  color: #F5F5F5;
  letter-spacing: 1.1px;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 34px;
  transition: 0.5s;
  &:hover {
    color: #303030 !important;
  }
}

.nav-item {
  transition: 0.5s;
}

/* .navLink:hover {
  color: #303030 !important;
} */

.loginbtn {
  letter-spacing: 1px;
  font-weight: 400;
}

.nav-side {
  display: flex;
  letter-spacing: 2px;
  color: #F5F5F5 !important;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 0px;
  /* margin: 0px; */
  text-decoration: none !important;
}

.side-icon {
  font-size: 25px;
}

.nav-side > svg:hover {
  color: #303030;
}

.dropdown-item {
  font-weight: 300;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 36p;
  color: #F5F5F5;
}

.dropdown-item:hover {
  background-color: #F5F5F5;
  color: #303030;
}

.logo-image {
  width: 100px;
  height: 80px;
  font-weight: 700px;
  left: 5px;
  padding-left: 25px;
  color: black;
  font-size: 50px;
  vertical-align: middle;
}

/* .nav-text {
  font-size: 50px;
  font-weight: 400;
  font-family: serif, sans-serif;
  vertical-align: middle;
  letter-spacing: 10px;
  color: #303030;
  text-align: center !important;
  padding-left: 350px;
  padding-right: 350px;
} */

.nav-text {
  height: 133px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 35px;
  font-weight: 700;
  background: #F5F5F5;
}

.carousel-item > img {
  width: 100%;
}

.navbar-light .navbar-toggler {
  color: #303030;
  border-color: #F5F5F5;
  background: #F5F5F5;
}

.nav-item:hover {
  background-color: #F5F5F5;
  color: #303030;
}

.dropdown-menu {
  background-color: #303030;
  color: #F5F5F5;
}

.arjivhead h1 {
  color: #303030;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
}

.arjivhead:hover {
  color: #303030;
  text-decoration: none;
}

.body {
  background-color: #303030;
}

.subImage {
  background-color: #303030;
}

.subitem {
  padding-top: 20px;
}

.subTextpart {
  color: #303030;
}

/* .subhead {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none !important;
  font-family: optima_nova_lt_regular, sans-serif;
} */

.bodyimage {
  width: 100%;
  height: auto;
}

.journey {
  background-color: #181818;
  height: 480px;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}
/* .card {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle !important;
  background-color: #F5F5F5 !important;

  border-radius: 0% !important;
} */

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
.sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
}

.sm-holder {
  display: flex;
  align-items: center;
}

.footer-text {
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.contact-section {
  background-color: #F5F5F5;
}

/* shift */

.body {
  background-color: #303030;
}

.subImage {
  background-color: #303030;
}

.subitem {
  padding-top: 20px;
}

.subTextpart {
  color: #303030;
}

/* .subhead {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none !important;
  font-family: optima_nova_lt_regular, sans-serif;
} */

.bodyimage {
  width: 100%;
  height: auto;
}

.journey {
  background-color: #181818;
  height: 480px;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}
.card {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle !important;
  /* border: #F5F5F5; */
  border-radius: 0% !important;
}

.copyright {
  height: 55px;
  color: #cbdcd9 !important;
  text-align: center;
  font-weight: 400;
  display: block;
  padding-top: 0.95rem;
  background-color: #303030;
  font-family: optima_nova_lt_regular, sans-serif !important;
  font-size: 14px;
  letter-spacing: 1px;
}

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
.sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
}

.sm-holder {
  display: flex;
  align-items: center;
}

.number {
  color: #303030 !important;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0px;
  word-spacing: 5px;
}

.sicon {
  font-size: 25px;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  border: dashed 1px #303030;
  margin: 5px;
  border-radius: 50%;
  transition: 0.5s;
  &:hover {
    transform: translateY(-5px);
    background: #DDD;
  }
}

/* .sicon:hover {
  border: solid 1px #303030;
} */

.footer-card {
  padding-top: 20px;
  padding-bottom: 0%;
  font-size: 22px;
  text-align: center;
}

.footer-fcard {
  background: #F5F5F5;
}

.footer-text {
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.contact-section {
  background-color: #F5F5F5;
}

a {
  color: #cbdcd9;
  transition: 0.4s;
  &:hover {
    color: #F5F5F5;
  }
}

.nav {
  display: block;
}

.dropdownmenusocial {
  min-width: 2rem !important;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.dropdown-item-social {
  padding: 0;
  margin: 0;
  font-size: 20px;
  .nav-link {
    color: #212121 !important;
  }
}

.nav-link:hover {
  color: #303030;
}

.socialmediatogle::after {
  display: none;
}

.navbar-collapse {
  z-index: 11;
  /* background-color: #303030; */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #F5F5F5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #79797c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

.pointer {
  cursor: pointer;
}
.rjcinfo {
  transition: 1.5s;
  &:hover {
    -webkit-filter: drop-shadow(0 0 15px #D8D8D8);
            filter: drop-shadow(0 0 15px #D8D8D8);
  }
}

.cookie {
  bottom: 20px;
  position: fixed;
  left: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-width: 420px;
  border-radius: 20px !important;
  h3 {
    grid-gap: 5px;
    gap: 5px;
    font-weight: 700;
  }
  p, button {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }
}

.backToTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: -100px;
  bottom: 40px;
  transition: right 0.5s;
  font-size: 10vw;
  padding: 0px;
  border-radius: 100%;
  z-index: 999;
  background-color: #303030 !important; 
  font-size: 10vw !important;
  -webkit-filter: drop-shadow(0, 0, 10px, #f5f5f5);
          filter: drop-shadow(0, 0, 10px, #f5f5f5);
  &.backTopTra {
    right: 20px;
  }
  svg, img {
    height: auto;
    width: auto;
    fill: #F5F5F5 !important;
  }
}

/* ---------------------------- */

.events {
  .zoom {
    img {
      aspect-ratio: 8/5;
    }
  }
}

.liveEvents {
  color: #EEE;
  background-color: #303030;
  .live-events-details {
    color: #303030;
    transition: 0.5s all;
    .show-details-box {
      background-color: #F5F5F5;
      height: 100%;
      font-family: 'Roboto';
    }
    h4 {
      font-size: 20px;
      font-family: 'Roboto';
    }
    h6 {
      font-size: 14px;
    }
    .description {
      font-size: 15px;
    }
    img {
      min-height: 230px;
      height: 230px;
      object-fit: contain;
    }
    h3 {
      font-weight: 800;
    }
  }
}

/* ---------------------------- */

.rjc-banner {
  background: #212121;
  font-family: 'optima_nova_lt_regular';
  h2 {
    font-size: 1.8rem;
    line-height: 40px;
  }
  h3 {
    font-size: 1.4rem;
    line-height: 35px;
  }
  img {
    max-width: 600px;
    margin-right: 10px;
  }
}

.highlight {
  color: #d7b264;
  font-weight: 900  ;
}

/* ---------------------------- */

.originNAlliances {
  background-color: #303030;
  h2 {
    /* font-size: 5  vh; */
    letter-spacing: 4px;
    color: white;
  }
  .origin-details{
    grid-gap: 50px;
    gap: 50px;
    .origin-details-box {
      cursor: pointer;
      img {
        /* max-width: 150px; */
        max-width: 135px;
      }
      .primary-holds {
        max-width: 350px;
        width: 350px;
      }
    }
  }
}

.grading {
  background-color: #ebebeb;
  .grading-area {
    padding: 120px 0;
    color: #F5F5F5;
    background: url(/static/media/bg-1.b9c55adc.jpg) no-repeat center center / cover;
    position: relative;
    z-index: 8;
    h1 {
      font-size: 60px;
    }
    &::before {
      position: absolute;
      content: '';
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: #303030;
      opacity: 0.6;
      z-index: -1;
    }
    p {
      font-size: 22px;
    }
  }
  p {
    font-size: 18px;
  }
  .grading-detail-area {
    max-width: 1440px;
    margin: auto;
    color: #303030;
    .grading-details {
      border-bottom: solid 2px darkgray;
      padding-block: 25px 50px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      h2 {
        position: relative;
        margin-bottom: 25px;
        text-transform: capitalize;
        /* &::before {
          content: '';
          position: absolute;
          width: 75px;
          background-color: #313131d7;
          height: 1.5px;
          bottom: -8px;
        } */
      }
    }
  }
}

table {
  /* display: table-row-group;
  overflow-x: scroll; */
  tr {
    td,th {
      font-size: 20px;
      padding: 5px;
      font-weight: 700;
      font-family: 'Poppins', sans-serif;
      white-space: pre;
      padding-inline: 10px;
    }
  }
  .font-normal {
    tr, td {
      font-weight: 400;
    }
  }
}

 /* Base Header Styles */
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: auto;
  z-index: 999;
  transition: 0.5s;
  padding-block: 25px;
}

.fixed-header {
  background-color: #F5F5F5;
  padding: 8px 0;
  box-shadow: 0 0 10px #303030;
  .navLink {
    color: #212121;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #212121;
      transition: width 0.5s ease-in-out;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .arjivhead {
    -webkit-filter: invert(1);
            filter: invert(1);
  }
  .toggle-menu {
    color: #303030;
  }
}
.dropdown-item {
  color: #212121;
}
.dropdown-menu {
  background-color: #FFF;
  color: #212121;
}

.banner {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background: url(/static/media/bg-2.e789f07f.jpg) no-repeat center / cover;
  position: relative;
  color: #F5F5F5;
  .banner-details {
    
    margin-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    inset: 0 0 0  0;
    z-index: 99;
    h1 {
      font-size: 72px;
    }
  }
}

.imagesection {
  .image-box {
    background: #F5F5F5;
    h3 {
      position: relative;
      font-weight: 700;
      &::before {
        content: "";
        background: #303030;
        width: 75px;
        height: 2px;
        position: absolute;
        bottom: 3px;
      }
    }
  }
  .dark-area {
    background: #303030;
    p {
      margin-top: 15px;
      text-align: left;
      font-size: 20px;
    }
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-x: auto;
}

.mw-mc {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}
.w-mc {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.arjiv-blogs {
  text-align: left;
  color: #414143;

  .blog-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      &:hover {
        transform: translateY(-8px);
      }
    }

    img {
      min-height: 230px;
      max-height: 230px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .blog-box-details {
      background-color: #F5F5F5;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 12px;
    }

    h5 {
      font-family: 'optima_nova_lt_regular';
      font-size: 20px;
      color: #414143;
      font-weight: 600;
    }
    
    p {
      font-family: 'Poppins';
      letter-spacing: 0.2px;
      line-height: 20px;
      font-size: 14px;
      color: #414143;
    }
  }
}
/* -------------------------------------------- */
.arjiv-crumb {
  background-color: #303030;
  h1 {
    font-size: 5vh;
  }
  a {
    text-align: center;
    font-size: 20px;
    text-transform: capitalize;
    color: #303030;
  }
  .breadcrumb-item {
    align-items: center;
  }
  .active {
    font-size: 20px;
    color: #303030;
    text-transform: capitalize;
  }
}

.bg-arjiv {
  background-color: #f5f5f5;
  background: #F5F5F5 url('https://betaadmin.arjivexports.com/static/media/chatBg.fa007583.png') center/contain;
}
.arjiv-article {
  border: solid 1px #bebebe;
  max-width: 800px;
  margin: 0 auto;
  background-color: #d6d6d6d7;
  background-color: #f5f5f58e;
  background-color: #e9e9e98e;
  color: #414143;
  border-radius: 10px;
  box-shadow: 0 0 10px #b1b1b1;
  .article-header {
    border-bottom: solid 1px #D8D8D8;
    border-bottom: dashed 1px #bebebe;
    padding: 15px 0;
    text-align: start;
  }
  .article-content {
    text-align: start;
    h2 {
      font-family: 'optima_nova_lt_regular';
      font-weight: 600;
      display: list-item;
      margin-left: 30px;
      margin-bottom: 15px;
    }
    img {
      width: 100%;
      box-shadow: 0 0 10px #C8C8C8;
      margin-bottom: 15px;
      border-radius: 5px;
    }
    p, li {
      font-size: 18px;
      line-height: 1.4;
      text-align: justify;
      font-family: 'Poppins';
      color: #414143;
      border-bottom: dashed 1px #bebebe;
      padding-bottom: 15px;
      &:last-child {
        border-bottom: none;
      } 
    }
    /* Ordered List Styling */
    .blog-section-list {
      list-style-type: disc;
      padding-left: 15px;
    }

    .blog-section-list li {
      border: none;
      list-style-type: disc;
    }
  }
  h1 {
    font-family: 'optima_nova_lt_regular';
    font-weight: 600;
    font-size: 44px;
  }
}
/* -------------------------------------------- */



/* body {
  cursor: url(http://localhost:3000/static/media/G-H.a293656c.png) 17 17, pointer !important;
} */
/* Screen Size 1100 px */

@media (max-width: 1366px) and (min-width: 992px) {
  .originNAlliances {
    .origin-details{
      .origin-details-box {
        img {
          max-width: 100%;
        }
        .primary-holds {
          max-width: 200px;
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .navLink {
    font-size: 15px;
  }
}

/* Screen Size 992 px */

@media (max-width: 992px) {
   /* -------------------RESPONSIVE HEADER-------------------- */
  .toggle-menu {
    display: block;
    color: #FFF;
    width: 30px;
    height: 30px;
  }
  .header {
    display: block;
    padding-inline: 10px;
    .close-sidebar-btn {
      right: 3%;
      top: 3%;
      width: 30px;
      height: 30px;
    }
    .collapse .show .navbar-collapse {
      background-color: #F5F5F5;
    }
    .navbar-collapse {
      position: fixed;
      overflow: hidden;
      max-width: 320px;
      width: 100%;
      background: #F5F5F5;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-100%);
      transition: 0.5s;
      -webkit-filter: drop-shadow(0px 0px 15px #303030);
              filter: drop-shadow(0px 0px 15px #303030);
      border-radius: 0 10px 10px 0;
      .navLink {
        color: #303030;
      }
    }
    .navbar-collapse.show {
      transform: translateX(0);
    }
    .fixed-header {
      position: fixed;
    }
  }
  .grading {
    p {
      font-size: 17px;
    }
  }
  .originNAlliances {
    .origin-details{
      .origin-details-box {
        img {
          max-width: 100%;
        }
      }
    }
  }
  /* -------------------RESPONSIVE HEADER-------------------- */
}

/* Screen Size 767 px */

@media (max-width: 768px) {

  .loginbtn {
    letter-spacing: 1px;
  }

  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 13px;
    font-size: 45px;
    line-height: 65px;
    font-weight: 600;
  }
  .logo img.img-fluid {
    width: 75px;
    position: absolute;
    left: 40px;
  }

  .logo-container {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }
  .copyright {
    height: 50px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .originNAlliances {
    .origin-details{
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      gap: 40px;
      img {
        max-width: 130px;
      }
    }
  }
  .rjc-banner {
    text-align: center;
  }
  .grading {
    .grading-detail-area {
      .grading-details {
        padding-block: 15px;
        .base-grid {
          display: grid !important;
          grid-template-columns: repeat(5, 1fr);
          grid-row-gap: 15px;
          row-gap: 15px;
          img {
            max-width: 90px;
          }
        }
      }
    }
  }
}

/* Screen Size 576px */
@media (max-width: 576px) {
  .rjc-banner {

    h2,
    h3 {
      justify-content: center;
      font-size: 100%;
      justify-content: space-evenly;
      line-height: normal;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .imagesection {
    text-align: center;

    .image-box {
      &:nth-child(even) {
        background-color: #e9e9e9;
      }

      h3 {
        &::before {
          bottom: 3px;
          width: 50%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .originNAlliances {
    .origin-details {
      img {
        max-width: 100px;
      }
    }
  }
  .grading {
    .grading-detail-area {
      .grading-details {
        .base-grid {
          grid-template-columns: repeat(3, 1fr);
          img {
            max-width: 80pxcd;
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 11px;
    font-size: 40px;
    padding-left: 15px;
  }
  .logo img.img-fluid {
    width: 70px;
    position: absolute;
    left: 15px;
  }

  .logo-container {
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }

  .sicon {
    font-size: 22px;
  }

  .copyright {
    height: 45px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 12px;
    letter-spacing: 0.7px;
  }
  .footer-card {
    padding-top: 18px;
    padding-bottom: 0%;
    font-size: 20px;
    text-align: center;
  }
  .footer-text {
    padding-bottom: 17px;
    font-size: 14px;
    text-align: center;
  }
  .number {
    color: #303030 !important;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0px;
    word-spacing: 5px;
  }
  .grading {
    p {
      font-size: 15px;
    }
  }
}

/* Screen Size 400px */
@media (max-width: 375px) {
  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 9px;
    font-size: 35px;
    padding-left: 15px;
  }
  .logo img.img-fluid {
    width: 65px;
    position: absolute;
    left: 10px;
  }
  .logo-container {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }

  .sicon {
    font-size: 16px;
  }

  .copyright {
    height: 42px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 10px;
    letter-spacing: 0.7px;
  }
  .footer-card {
    padding-top: 16px;
    padding-bottom: 0%;
    font-size: 18px;
    text-align: center;
  }
  .footer-text {
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
  }
  .number {
    color: #303030 !important;
    font-size: 14px;
    box-sizing: border-box;
    padding: 0px;
    word-spacing: 4px;
  }
  .grading {
    p {
      font-size: 14px;
    }
    .grading-detail-area {
      .grading-details {
        .base-grid {
          grid-template-columns: repeat(2, 1fr);
          img {
            max-width: 50px;
          }
        }
      }
    }
  }
}

.body {
  background-color: #303030;
}

.sub {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}

.subImage {
  background-color: #303030;
}

/* .subitem {
  padding-top: 20px;
} */

.subTextpart {
  color: #303030;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 0; 
  opacity: 1;
}

.subhead {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}

.cardtext {
  background-color: #F5F5F5;
}

/* .subbutton {
  color: #F5F5F5;
  width: 98px;
  height: 45px;
  font-size: 15px;
  line-height: 30px;
} */

/* .bodyimage {
  width: 100%;
  height: 480px;
} */

.journey {
  background-color: #181818;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
/* .sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
} */

/* .sm-holder {
  display: flex;
  align-items: center;
} */

.main-content {
  background-color: #303030;
  color: #f5f5f5;
  text-align: center;
  /* font-family: optima_nova_lt_regular, sans-serif; */
  font-family: 'Poppins', sans-serif;
  padding-top: 100px;
  /* margin-top: -100px */
}

.bg-secondary {
  background-color: #F5F5F5 !important;
  color: #303030;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
}

/* Zoom Effect */
.zoom {
  width: 99%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.zoom img {
  width: 100%;
  transition: 1s all ease-in-out;
  /* aspect-ratio: 2; */
  object-fit: cover;
}

.zoom:hover img {
  transform: scale(1.08);
}

.bgimage-handler {
  position: relative;
  text-align: center;
}

/* TODO */

.carousel-caption h3 {
  font-family: optima_nova_lt_regular, sans-serif;
  font-size: 3rem;
  -webkit-animation-name: slidertext;
          animation-name: slidertext;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  position: relative;
  color: #F5F5F5;
  right: 0rem;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes slidertext {
  from {
    right: 0rem;
    opacity: 0.3;
    font-size: 2rem;
  }
  to {
    right: 15rem;
    opacity: 1;
    font-size: 2rem;
  }
}

@keyframes slidertext {
  from {
    right: 0rem;
    opacity: 0.3;
    font-size: 2rem;
  }
  to {
    right: 15rem;
    opacity: 1;
    font-size: 2rem;
  }
}

.bg-image-text {
  position: absolute;
  bottom: 80px;
  left: 150px;
  font-size: 20px;
  line-height: 45px;
  background-color: transparent;
  color: #F5F5F5;
  font-style: normal;
  border: 1px solid #F5F5F5;
  font-family: optima_nova_lt_regular, sans-serif;
}

.bgimage {
  max-width: 100%;
  height: auto;
  display: block;
  font-family: optima_nova_lt_regular, sans-serif;
}

.bgtext-handler {
  height: 100px;
  background-color: #F5F5F5;
  color: #303030;
  text-align: center;
  font-size: 18px;
  text-transform: unset;
  padding: 40px 0%;
}
.cust img {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}

.custtext {
  color: #303030;
  font-family: optima_nova_lt_regular, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.custtitle {
  margin-bottom: 15px;
  line-height: 40px;
  font-size: 30px;
  font-weight: 500;
  text-transform: unset;
  word-wrap: break-word;
}

.custtextbody {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  text-transform: unset;
}

.title {
  text-align: left;
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 25px;
  color: #F5F5F5;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 1px;
}

.title2 {
  text-align: left;
  margin: 12px 0;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  word-wrap: break-word;
}

.contacttext {
  text-align: left;
  letter-spacing: 1px;
  font-size: 15px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 25px;
}

.btn-secondary {
  background-color: #303030;
  color: #F5F5F5;
  text-transform: capitalize;
  font-size: 1.1rem;
}

.btn-secondary:hover {
  background-color: #F5F5F5;
  color: #303030;
}

/* .carousel-item {
  position: relative;
  display: none;
  -webkit-transition: 0.2s ease-in-out left;
  -moz-transition: 0.2s ease-in-out left;
  -o-transition: 0.2s ease-in-out left;
  transition: 0.1s ease-in-out left;
} */

/* .carousel-item {
  transition-duration: 0.1s !important;
} */

.journeryTitle {
  height: 375px;
  background-color: #303030;
  font-family: optima_nova_lt_regular, sans-serif;
  margin: 0%;
}

/* Shape Select */
ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="cb"] {
  display: none;
}

label {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 3px;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  /* 4s */
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 60px;
  width: 60px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  border: solid 1px;
}

label span {
  transition-duration: 0.2s;
  border: solid 1px;
  height: 200px !important;
}

/* :checked + label {
  border-color: #ddd;
} */

/* :checked + label::before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
} */

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 15px #333;
  z-index: -1;
}

:checked + label span {
  transform: scale(0.9);
  box-shadow: 0 0 15px #333;
  z-index: -1;
}

.shapeitem-other {
  height: 600px;
}

.form-control {
  border-radius: 0rem !important;
}

.excellence {
  font-size: 35px;
  font-weight: 400;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: center;
  letter-spacing: 1px;
  color: #303030;
  word-spacing: 5px;
  vertical-align: middle !important;
  padding-bottom: 10px;
  padding-top: 20px;
}

.nav-2 {
  background-color: #F5F5F5;
  height: 100px;
  vertical-align: middle !important;
}

.thejourneybutton {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.journeybutton {
  font-size: 20px;
  font-family: optima_nova_lt_regular, sans-serif;
  width: 204px;
  transition: width 0.4s;
  -webkit-animation-name: journeybuttonanimation;
          animation-name: journeybuttonanimation;
}

.journeybutton:hover {
  background-color: #F5F5F5;
  color: #181818;
  width: 230px;
  -webkit-animation-name: journeybuttonanimation;
          animation-name: journeybuttonanimation;
}

textarea {
  resize: none;
}

@media (max-width: 1024px) {
  .cardtext {
    background-color: #F5F5F5;
    padding: 0rem 0.5rem !important;
    padding-top: 1rem !important;
  }
  .subhead {
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (max-width: 992px) {
  .main-content {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 80px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 30px;
    font-weight: 400;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 4px;
    vertical-align: middle !important;
    padding-bottom: 8px;
    padding-top: 18px;
  }
  .sub {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
  }
  .cardtext {
    background-color: #F5F5F5;
    padding: 0rem 0.2rem !important;
    padding-top: 0rem !important;
  }
  .subhead {
    margin-top: 10px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.2;
  }

  .subbutton {
    color: #F5F5F5;
    width: 98px;
    height: 42px;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .subtextbody {
    font-size: 15px;
    margin: 0 !important;
  }

  .journeryTitle h3 span {
    font-size: 54px !important;
  }

  .journeryTitle p {
    color: #F5F5F5;
    line-height: 30px;
    font-size: 17px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .journeryTitle {
    height: auto;
  }
}

@media (max-width: 576px) {
  .main-content {
    padding-top: 50px;
  }
}

@media (max-width: 425px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 60px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 25px;
    font-weight: 400;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 4px;
    vertical-align: middle !important;
    padding-bottom: 5px;
    padding-top: 15px;
  }
  .sub {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .journeryTitle p {
    line-height: 27px !important;
    font-size: 15px !important;
    width: 90% !important;
  }

  .journeryTitle h6 {
    font-size: 24px !important;
  }
}

@media (max-width: 375px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 50px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 20px;
    font-weight: 300;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 2px;
    vertical-align: middle !important;
    padding-bottom: 3px;
    padding-top: 11px;
  }

  .sub {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
}

/******************* Timeline Demo - 9 *****************/
.main-timeline9 {
  position: relative;
}

.main-timeline9:after,
.main-timeline9:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline9:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #F5F5F5;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-timeline9 .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}

.main-timeline9 .timeline:after,
.main-timeline9 .timeline:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline9 .timeline:first-child:before,
.main-timeline9 .timeline:last-child:before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #F5F5F5;
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
}

.main-timeline9 .timeline:last-child:before {
  top: auto;
  bottom: 0;
}

.main-timeline9 .timeline:last-child:nth-child(even):before {
  right: auto;
  left: -12px;
  bottom: -2px;
}

.main-timeline9 .timeline-content {
  text-align: center;
  margin-top: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    img {
      scale: 1.3;
    }
  }
}

.main-timeline9 .timeline-content:before {
  content: "";
  width: 100%;
  height: 5px;
  background: #F5F5F5;
  position: absolute;
  top: 88px;
  left: 0;
  z-index: -1;
}

.main-timeline9 .circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  background: #313131;
  border: 8px solid #F5F5F5;
  float: left;
  margin-right: 25px;
  margin-top: 25px;
  position: relative;
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before,
.main-timeline9 .circle:before {
  content: "";
  margin: auto;
  position: absolute;
  right: -33px;
  bottom: 0;
  z-index: -1;
}

.main-timeline9 .circle:before {
  width: 26px;
  height: 30px;
  background: #F5F5F5;
  top: 0;
  box-shadow: inset 7px 0 9px -7px #444;
}

.main-timeline9 .circle span {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 110px;
  border: 3px solid #adabab;
  font-size: 70px;
  color: #454344;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before {
  width: 28px;
  height: 50px;
  background: #303030;
  border-radius: 0 0 0 21px;
  top: -54px;
  border-color: #303030;
}

.main-timeline9 .circle span:after {
  border-radius: 21px 0 0;
  top: 0;
  bottom: -56px;
}

.main-timeline9 .content {
  display: table;
  padding-right: 40px;
  position: relative;
}

.main-timeline9 .year {
  display: block;
  padding: 10px;
  margin: 10px 0 50px;
  background: #67676b;
  border-radius: 7px;
  font-size: 25px;
  color: #fff;
}

.main-timeline9 .title {
  font-size: 25px;
  /* font-weight: 700; */
  color: #F5F5F5;
  margin-top: 0;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before,
.main-timeline9 .icon:before {
  content: "";
  height: 25px;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: -15px;
}

.main-timeline9 .description {
  font-size: 16px;
  color: #aaa;
  text-align: justify;
  margin-bottom: 20px;
  margin-top: 15px;
  letter-spacing: 0.5px;
}

.main-timeline9 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #F5F5F5;
  position: absolute;
  top: 78px;
  right: -14px;
}

.main-timeline9 .icon:before {
  width: 15px;
  background: #F5F5F5;
  top: -1px;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before {
  width: 21px;
  background: #303030;
  border-radius: 0 0 21px;
  top: -30px;
  border-color: #303030;
}

.main-timeline9 .icon span:after {
  border-radius: 0 21px 0 0;
  top: 0;
  left: -15px;
  bottom: -30px;
}

.main-timeline9 .timeline:nth-child(2n) .circle,
.main-timeline9 .timeline:nth-child(2n) .timeline-content {
  float: right;
}

.main-timeline9 .timeline:nth-child(2n) .circle {
  margin: 25px 0 0 25px;
}

.main-timeline9 .timeline:nth-child(2n) .circle:before {
  right: auto;
  left: -33px;
  box-shadow: -7px 0 9px -7px #444 inset;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after,
.main-timeline9 .timeline:nth-child(2n) .circle span:before {
  right: auto;
  left: -33px;
  border-radius: 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after {
  border-radius: 0 21px 0 0;
}

.main-timeline9 .timeline:nth-child(2n) .content {
  padding: 0 0 0 40px;
  margin-left: 2px;
}

.main-timeline9 .timeline:nth-child(2n) .icon {
  right: auto;
  left: -14px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after,
.main-timeline9 .timeline:nth-child(2n) .icon span:before,
.main-timeline9 .timeline:nth-child(2n) .icon:before {
  left: auto;
  right: -15px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:before {
  border-radius: 0 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after {
  border-radius: 21px 0 0;
}

/* .main-timeline9 .timeline:nth-child(2) {
  margin-top: 180px;
} */

.main-timeline9 .timeline:nth-child(odd) {
  margin: -135px 0 0;
}

.main-timeline9 .timeline:nth-child(even) {
  margin-top: 150px;
}

.main-timeline9 .timeline:first-child,
.main-timeline9 .timeline:last-child:nth-child(even) {
  margin: 0;
}
.timeline.tm-12 {
  margin-top: 50px;
}
.main-timeline9 .circle span img {
  width: 74px;
  /* margin-top: 8px; */
  transition: all 1s ease-in-out;
}
.main-timeline9 .timeline.tm-15 {
  margin-top: -110px !important;
}

@media only screen and (max-width: 990px) {
  .main-timeline9:before {
    left: 100%;
  }

  .main-timeline9 .timeline {
    width: 100%;
    float: none;
    margin-bottom: 20px !important;
  }

  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
    left: auto !important;
    right: -13px !important;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle {
    float: left;
    margin: 0 25px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle:before {
    right: -33px;
    left: auto;
    box-shadow: 7px 0 9px -7px #444 inset;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle span:after,
  .main-timeline9 .timeline:nth-child(2n) .circle span:before {
    right: -33px;
    left: auto;
    border-radius: 0 0 0 21px;
  }

  .main-timeline9 .timeline:nth-child(2n) .circle span:after {
    border-radius: 21px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .content {
    padding: 0 40px 0 0;
    margin-left: 0;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon {
    right: -14px;
    left: auto;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:after,
  .main-timeline9 .timeline:nth-child(2n) .icon span:before,
  .main-timeline9 .timeline:nth-child(2n) .icon:before {
    left: -15px;
    right: auto;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:before {
    border-radius: 0 0 21px;
  }

  .main-timeline9 .timeline:nth-child(2n) .icon span:after {
    border-radius: 0 21px 0 0;
  }

  .main-timeline9 .timeline:nth-child(2),
  .main-timeline9 .timeline:nth-child(even),
  .main-timeline9 .timeline:nth-child(odd) {
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  .main-timeline9:before {
    left: 0;
  }

  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
    left: -12px !important;
    right: auto !important;
  }

  .main-timeline9 .circle,
  .main-timeline9 .timeline:nth-child(2n) .circle {
    width: 130px;
    height: 130px;
    float: none;
    margin: 0 auto;
  }

  .main-timeline9 .timeline-content:before {
    width: 99.5%;
    top: 68px;
    left: 0.5%;
  }

  .main-timeline9 .circle span {
    line-height: 115px;
    font-size: 60px;
  }

  .main-timeline9 .circle span:after,
  .main-timeline9 .circle span:before,
  .main-timeline9 .circle:before,
  .main-timeline9 .icon {
    display: none;
  }

  .main-timeline9 .content,
  .main-timeline9 .timeline:nth-child(2n) .content {
    padding: 0 10px 0 30px;
  }

  .main-timeline9 .year {
    margin-bottom: 15px;
  }

  .main-timeline9 .description {
    text-align: justify;
  }
}

.journery-holder {
  background: #303030;
}

.journeryTitle h3,
.sectionTitle h3 {
  font-size: 30px;
  text-align: center;
  color: #F5F5F5;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}
.journeryTitle h3 span {
  font-size: 63px;
}
.journeryTitle h6 {
  color: #F5F5F5;
  font-size: 28px;
  /* margin-top: 10px; */
  text-align: center;
  width: 100%;
}

.journeryTitle p {
  color: #F5F5F5;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

@-webkit-keyframes wd-an {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes wd-an {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.loading-bar {
    z-index: 100000;
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: #00000083;

    .spinner {
        --size-of-spinner: 100px;
        --spinner-border-width: 7px;
        --spinner-color: #FFF;
        --circle-color: #4b4b4b00;
        --speed-of-animation: 2s;
        width: var(--size-of-spinner);
        height: var(--size-of-spinner);
        background: var(--circle-color);
        border-radius: 50%;
        position: relative;
    }
}

.spinner::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    inset: 0;
    border: 7px solid var(--spinner-color);
    border-right-color: transparent;
    -webkit-animation: spinny var(--speed-of-animation) linear infinite;
            animation: spinny var(--speed-of-animation) linear infinite;
}

@-webkit-keyframes spinny {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinny {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}
.model-content {
  font-family: optima_nova_lt_regular, sans-serif;
  background-color: #F5F5F5;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
  outline: 0;
  color: #303030 !important;
  text-align: left !important;
}

.modelheader {
  border-bottom: 1px solid;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  padding-left: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #F5F5F5;
  z-index: 9990;
}

.modelbody {
  border-bottom: 1px solid;
}

.modelfooter {
  /* font-size: 20px; */
  /* font-weight: 500; */
  /* line-height: 60px; */
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.modelfooter button {
  margin-left: 5px;
  background-color: #303030;
  color: #F5F5F5 !important;
}

.formgroup option {
  background-color: #F5F5F5;
  border: 1px solid;
}

.formgroup input {
  background-color: #F5F5F5;
  border: 1px solid;
}

.formgroup Col {
  background-color: #F5F5F5 !important;
  border: 1px solid;
}

#Select {
  background-color: #F5F5F5;
  border: 1px solid;
}

#fluorescence {
  background-color: #F5F5F5;
  border: 1px solid;
}

#message {
  background-color: #F5F5F5;
  border: 1px solid;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.arjiv-creation1 {
  position: absolute;
  top: 240px;
  left: 163px;
  font-size: 42px;
  font-weight: 700;
  color: rgb(224, 220, 217);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: left;
}

@media (max-width: 1024px) {
  .arjiv-creation1 {
    position: absolute;
    top: 100px;
    left: 90px;
    font-size: 35px;
    font-weight: 700;
    color: rgb(224, 220, 217);
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .arjiv-creation1 {
    position: absolute;
    top: 50px;
    left: 50px;
    font-size: 25px;
    font-weight: 600;
    color: rgb(224, 220, 217);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
  .bg-image-text {
    position: absolute;
    bottom: 50px;
    left: 100px;
    font-size: 18px;
    line-height: 40px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 1px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}

@media (max-width: 425px) {
  .arjiv-creation1 {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgb(224, 220, 217);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: left;
  }
  .bg-image-text {
    position: absolute;
    bottom: 15px;
    left: 65px;
    font-size: 17px;
    line-height: 30px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 0.5px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}

@media (max-width: 375px) {
  .bg-image-text {
    position: absolute;
    bottom: 15px;
    left: 65px;
    font-size: 17px;
    line-height: 30px;
    background-color: transparent;
    color: #F5F5F5;
    font-style: normal;
    border: 0.5px solid #F5F5F5;
    font-family: optima_nova_lt_regular, sans-serif;
  }
}

body {
  overflow-x: hidden !important;
}

.main-banners {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .tsparticles-canvas-el {
    -webkit-filter: invert(1);
            filter: invert(1);
    position: absolute;
    inset: 0;
    z-index: 14;
    object-fit: contain;
  }
  .banner-details {
    z-index: 9;
    position: relative;
    height: 100vh;
    background: url(/static/media/mine-source1.9184fa13.jpg) no-repeat 50% 80% fixed;
    .banner-details-content {
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: #000;
        opacity: 0.7;
      }
      img {
        object-fit: cover;
      }
      .banner-info {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
          font-size: 72px;
          font-size: 50px;
          /* font-size: 40px; */
          letter-spacing: 1px;
          text-transform: uppercase; 
          font-weight: 600;
          font-family: optima_nova_lt_regular, sans-serif;
          font-family: 'Poppins';
          font-family: "Montserrat", serif;
          font-weight: 700;
          margin-block: 40px;
        }
        p {
          font-size: 28px;
          font-size: 22px;
          letter-spacing: 1px;
          max-width: 800px;
          margin: 0 auto;
          /* font-family: 'Poppins'; */
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    
    li {
      list-style: none;
      margin: 0 5px;
      width: 14px;
      height: 14px;
      button {
        background-color: transparent;
        border: none;
        color: transparent;
        font-size: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: block;
        transition: background-color 0.3s ease-in-out;
  
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #bbb; 
        }
  
        &:hover:before {
          background-color: #F5F5F5; 
        }
      }
      &.slick-active {
        button {
          &:before {
            background-color: #FFF; 
          }
        }
      }
    }
  }
}

/* -------------------------------- */

/* M I L E S T O N E */

.milestone-container {
  max-width: 991px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  .milestone-header {
    text-align: left;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .milestone-wrapper {
    position: relative;
    padding: 80px 0 30px;
    border-left: 5px dashed #F5F5F5;
    border-spacing: 10px;
    .milestone-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
      position: relative;
      .milestone-year-box {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        .milestone-year {
          position: relative;
          top: 10px;
          left: -53px;
          bottom: 50px;
          height: 100px;
          width: 100px;
          background-color: #F5F5F5;
          color: #414143;
          font-size: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 3px #151515;
          img {
            width: 60px;
            height: 60px;
            aspect-ratio: 1;
            object-fit: contain;
          }
        }
      }
    }
    .milestone-content {
      margin-left: 20px;
      background-color: white;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      width: 100%;
      grid-gap: 10px;
      gap: 10px;
      .arjiv-evolution {
        grid-gap: 10px;
        gap: 10px;
      }
      h3 {
        font-size: 28px;
        color: #414143;
      }
      p {
        font-size: 18px;
        margin: 0;
        color: #414143;
      }
      img {
        max-width: 100px;
        max-height: 100px;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1;
      }
    }
  }
}

/* M I L E S T O N E */

/* -------------------------------- */


/* -------------------------------- */

/* G R I D B A N N E R */

.grid-banner {
  .main-content {
    background-color: #212121;
  }
  .grid-wrapper{
    height: calc(100vh - 100px);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .grid-box{
      /* background-color: yellow; */
      border-right: solid 2px #F5F5F5;
      align-content: start;
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      transition: 0.5s;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* justify-content: space-around; */
      flex-direction: column;
      video {
        transition: 3s;
        height: 75vh;
        object-fit: cover;
      }
      .mask-box {
        height: 75vh;
        transition: 3s;
        height: 75vh;
        object-fit: cover;
        overflow: hidden;
      }
      h2 {
        margin-block: 50px;
        text-transform: uppercase;
      }
      &:hover {
        -webkit-filter: grayscale(0);
                filter: grayscale(0);
        video {
          scale: 1.2;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        align-content: end;
      }
      &:last-child {
        border: none;
      }
    }
  }
}

/* G R I D B A N N E R */

.our-future{
  color: #F5F5F5;
}

.policy {
  p {
    font-family: 'Poppins', sans-serif;
    line-height: normal;
  }
}
.darken-bg {
  padding-block: 120px;
  z-index: 10;
  overflow: hidden;
  color: #F5F5F5;
  background: url(/static/media/mine-source.1245c818.jpg) no-repeat 50% 80% fixed; background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #000;
    opacity: 0.6;
    z-index: -1;
  }
  .testimonial-style-2 .testimonial-item .testimonial-content {
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;
    z-index: 99;
    position: relative;
  }
  p {
    font-size: 30px;
    .quote-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url(/static/media/quote.2dfa2841.svg) no-repeat center;
      background-size: contain;
    }
  }
  h6 {
    font-size: 18px;
  }

}


.customization {
  height: 100%;
  position: relative;
  .customization-details {
    position: absolute;
    inset: 0;
    color: #F5F5F5;
    p, button {
      font-size: 20px;
    }
    h4 {
      font-family: optima_nova_lt_regular, sans-serif;
      font-size: 2vmax;
      font-size: 4vmax;
      letter-spacing: 0.5px;
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 40PX;
      max-width: 530px;
      line-height: 32px;
      font-weight: normal;
      letter-spacing: 1px;
    }
    button {
      letter-spacing: 5PX;
    }
    h3 {
      font-size: 2.5vmax;
      font-family: "Roboto", sans-serif;  
      max-width: -webkit-max-content;  
      max-width: -moz-max-content;  
      max-width: max-content;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 15px;
      letter-spacing: 2px;
      position: relative;
      display: inline-block;
      &::before {
        content: '';
        position: absolute;
        bottom: -30px;
        background: #F5F5F5;
        height: 2px;
        width: 200px;
        width: 150px;
        z-index: 1;
      }
    }
  }
}

.btn-light-dark {
  border-color: #303030;
  color: #303030;
  &:hover {
    background: #303030;
    color: #f5f5f5;
  }
}
/* 
.layout-match-pair {
  padding: 50px 20px;
  background-color: #f4f4f4;

  .pair-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .pair-item {
      display: flex;
      align-items: center;
      gap: 20px;

      &.reverse {
        flex-direction: row-reverse;
      }

      .pair-image {
        flex: 1;
        max-width: 50%;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      .pair-content {
        flex: 1;
        text-align: center;

        .pair-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #333;
        }

        .pair-description {
          font-size: 16px;
          line-height: 1.6;
          color: #555;
        }
      }
    }
  }
} */

.layout-matchPair {
  background-color: #151515;
  background-color: radial-gradient(circle, #626262 0%, #262626 100%);
  color: #F5F5F5;
  .main-row {
    max-width: 1260px;
  }
  .box-img {
    img {
      aspect-ratio: 1;
      max-height: 650px;
      object-fit: cover;
      /* box-shadow: 10px 10px 1px #F5F5F5; */
    }
    .reverse-img {
      margin-top: -75px;
      /* box-shadow: -10px -10px 1px #ffffff; */
    }
  }
  .box-details {
    position: relative;
    z-index: 9;
    &::before {
      content: '';
      position: absolute;
      inset: -15% 0 0 5%;
      width: 250px;
      height: 250px;
      background-color: #f5f5f51a;
      border-radius: 100%;
      z-index: -1;
      opacity: 1;
      -webkit-animation: slideFigure 15s ease-in-out infinite;
              animation: slideFigure 15s ease-in-out infinite;
    }
    img {
      -webkit-filter: invert(1);
              filter: invert(1);
      margin-right: 15px;
    }
  }
  h3 {
    font-family: optima_nova_lt_regular, sans-serif;
    font-size: 2.5rem;
    line-height: normal;
    position: relative;
    font-weight: 700;
    &::before {
      content: "";
      background: #F5F5F5;
      width: 150px;
      height: 2px;
      position: absolute;
      bottom: 3px;
    }
  }
  p {
    font-size: 24px;
  }
}

.what-we-do {
  .process-content {
    padding: 75px 0;
    .process-box {
      border-bottom: dashed 2px #555555;
      padding-bottom: 50px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
      }
      video {
        max-height: 500px;
        aspect-ratio: 1;
        object-fit: cover;
      }
      video, img{
        box-shadow: 0 25px 50px -12px #181818;
      }
      .flip-mirror {
        transform: scaleX(-1);
      }      
      .process-title {
        font-size: 32px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: solid 2px white;
        max-width: -webkit-max-content;
        max-width: -moz-max-content;
        max-width: max-content;
      }
      .process-description {
        font-size: 20px;
      }
    }
  }
}

.notfound {
  height: 75vh;
  h1 {
    font-size: 210px;
    letter-spacing: 10px;
    font-family: monospace, sans-serif;
    font-weight: 600;
  }
  h2 {

    font-size: 64px;
  }
}

@-webkit-keyframes slideFigure {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(30px); 
  }
  100% {
    transform: translateX(0px);  
  }
}

@keyframes slideFigure {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(30px); 
  }
  100% {
    transform: translateX(0px);  
  }
}

/* -------------------------------- */


@media screen and (max-width: 1299px) {
  .customization {
    /* padding-bottom: 50px; */
    .customization-details {
      h4 {
        font-size: 2vmax
      }
      h3 {
        font-size: 2.5vmax;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .customization {
    /* padding-bottom: 100px; */
    .customization-details {
      position: unset;
    }
  }
  .grid-banner {
    .main-content {
      background-color: #212121;
    }
    .main-content {
      padding-top: 0 !important; 
    }
    .grid-wrapper{
      grid-template-columns: repeat(1, 1fr);
      height: 100vh;
      .grid-box{
        align-content: start;
        display: unset;
        video {
          height: 100%;
        }
        .mask-box {
          height: 100%;
        }
        h2 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-banner {
    .banner-details {
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .main-banners {
    /* height: 75vh; */
    .banner-details {
      .banner-details-content {
        .banner-info {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 22px;
            line-height: normal;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .customization {
    background-color: #F5F5F5;
    .customization-details {
      color: #212121;
      h4 {
        font-size: 22px
      }
      h3 {
        font-size: 24px;
        &::before {
          background: #303030;
          bottom: -21px;
        }
      }
      button {
        font-size: 16px;
        padding: 10px !important;
      }
      .btn-outline-light {
        border-color: #303030;
        color: #303030;
        &:hover {
          background: #303030;
          color: #f5f5f5;
        }
      }
    }
  }
  .milestone-container {
    padding: 20px 0;
    .milestone-wrapper {
      padding: 50px 0;
      margin-left: 40px;
      .milestone-content {
        margin-left: -20px;
        img {
          max-width: 80px;
          max-height: 80px;
          margin-top: 5px;
        }
      }
      .milestone-item {
        .milestone-year-box {
          .milestone-year {
            height: 80px;
            width: 80px;
            left: -42px;
          }
        }
      }
    }
  }
  .notfound {
    h1 {
      font-size: 52px;
    }
    h2 {
      font-size: 24px;
    }
  }
}

body {
  margin: 0;
  font-family: optima_nova_lt_regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "optima_nova_lt_regular";
  src: local("optima_nova_lt_regular"),
    url(/static/media/OptimanovaLTRegular.3a861eca.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "FiraSans";
  src: local("FiraSans"), url(./fonts/FiraSans-Regular.ttf) format("truetype");
} */

