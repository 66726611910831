@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,h2,h3,h4,h5,h6 {
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}
*{
  margin: 0;
  padding: 0;
  font-family: optima_nova_lt_regular, sans-serif;
  /* font-family: "Poppins", sans-serif; */
}
img {
  max-width: 100%;
  height: auto;
}
p{
  /* font-family: "RocknRoll One", sans-serif; */
  &:last-child {
    margin-bottom: 0;
  }
}
.row {
  margin-left: auto;
  margin-right: auto;
}
.logo-container {
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  background: #F5F5F5;
  padding: 0 0;
}

.logo img.img-fluid {
  width: 80px;
  position: absolute;
  left: 45px;
}
.logo-container p h1 {
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 50px;
  line-height: 75px;
  font-weight: 700;
}

.logo {
  width: 100%;
  position: relative;
  text-align: center;
}

/* ------------------------------------------ */
.nav-1 {
  background-color: #303030;
  color: aliceblue;
  padding: 0;
  transition: background-color 0.3s, padding 0.3s;
  background-color: transparent;
  /* height: 50px; */
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #303030 !important;
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #F5F5F5;
}

.navLink {
  color: #F5F5F5;
  letter-spacing: 1.1px;
  font-weight: 300;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 34px;
  transition: 0.5s;
  &:hover {
    color: #303030 !important;
  }
}

.nav-item {
  transition: 0.5s;
}

/* .navLink:hover {
  color: #303030 !important;
} */

.loginbtn {
  letter-spacing: 1px;
  font-weight: 400;
}

.nav-side {
  display: flex;
  letter-spacing: 2px;
  color: #F5F5F5 !important;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 0px;
  /* margin: 0px; */
  text-decoration: none !important;
}

.side-icon {
  font-size: 25px;
}

.nav-side > svg:hover {
  color: #303030;
}

.dropdown-item {
  font-weight: 300;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 36p;
  color: #F5F5F5;
}

.dropdown-item:hover {
  background-color: #F5F5F5;
  color: #303030;
}

.logo-image {
  width: 100px;
  height: 80px;
  font-weight: 700px;
  left: 5px;
  padding-left: 25px;
  color: black;
  font-size: 50px;
  vertical-align: middle;
}

/* .nav-text {
  font-size: 50px;
  font-weight: 400;
  font-family: serif, sans-serif;
  vertical-align: middle;
  letter-spacing: 10px;
  color: #303030;
  text-align: center !important;
  padding-left: 350px;
  padding-right: 350px;
} */

.nav-text {
  height: 133px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 35px;
  font-weight: 700;
  background: #F5F5F5;
}

.carousel-item > img {
  width: 100%;
}

.navbar-light .navbar-toggler {
  color: #303030;
  border-color: #F5F5F5;
  background: #F5F5F5;
}

.nav-item:hover {
  background-color: #F5F5F5;
  color: #303030;
}

.dropdown-menu {
  background-color: #303030;
  color: #F5F5F5;
}

.arjivhead h1 {
  color: #303030;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
}

.arjivhead:hover {
  color: #303030;
  text-decoration: none;
}

.body {
  background-color: #303030;
}

.subImage {
  background-color: #303030;
}

.subitem {
  padding-top: 20px;
}

.subTextpart {
  color: #303030;
}

/* .subhead {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none !important;
  font-family: optima_nova_lt_regular, sans-serif;
} */

.bodyimage {
  width: 100%;
  height: auto;
}

.journey {
  background-color: #181818;
  height: 480px;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}
/* .card {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle !important;
  background-color: #F5F5F5 !important;

  border-radius: 0% !important;
} */

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
.sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
}

.sm-holder {
  display: flex;
  align-items: center;
}

.footer-text {
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.contact-section {
  background-color: #F5F5F5;
}

/* shift */

.body {
  background-color: #303030;
}

.subImage {
  background-color: #303030;
}

.subitem {
  padding-top: 20px;
}

.subTextpart {
  color: #303030;
}

/* .subhead {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none !important;
  font-family: optima_nova_lt_regular, sans-serif;
} */

.bodyimage {
  width: 100%;
  height: auto;
}

.journey {
  background-color: #181818;
  height: 480px;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}
.card {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle !important;
  /* border: #F5F5F5; */
  border-radius: 0% !important;
}

.copyright {
  height: 55px;
  color: #cbdcd9 !important;
  text-align: center;
  font-weight: 400;
  display: block;
  padding-top: 0.95rem;
  background-color: #303030;
  font-family: optima_nova_lt_regular, sans-serif !important;
  font-size: 14px;
  letter-spacing: 1px;
}

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
.sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
}

.sm-holder {
  display: flex;
  align-items: center;
}

.number {
  color: #303030 !important;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0px;
  word-spacing: 5px;
}

.sicon {
  font-size: 25px;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  border: dashed 1px #303030;
  margin: 5px;
  border-radius: 50%;
  transition: 0.5s;
  &:hover {
    transform: translateY(-5px);
    background: #DDD;
  }
}

/* .sicon:hover {
  border: solid 1px #303030;
} */

.footer-card {
  padding-top: 20px;
  padding-bottom: 0%;
  font-size: 22px;
  text-align: center;
}

.footer-fcard {
  background: #F5F5F5;
}

.footer-text {
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.contact-section {
  background-color: #F5F5F5;
}

a {
  color: #cbdcd9;
  transition: 0.4s;
  &:hover {
    color: #F5F5F5;
  }
}

.nav {
  display: block;
}

.dropdownmenusocial {
  min-width: 2rem !important;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.dropdown-item-social {
  padding: 0;
  margin: 0;
  font-size: 20px;
  .nav-link {
    color: #212121 !important;
  }
}

.nav-link:hover {
  color: #303030;
}

.socialmediatogle::after {
  display: none;
}

.navbar-collapse {
  z-index: 11;
  /* background-color: #303030; */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #F5F5F5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #79797c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

.pointer {
  cursor: pointer;
}
.rjcinfo {
  transition: 1.5s;
  &:hover {
    filter: drop-shadow(0 0 15px #D8D8D8);
  }
}

.cookie {
  bottom: 20px;
  position: fixed;
  left: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-width: 420px;
  border-radius: 20px !important;
  h3 {
    gap: 5px;
    font-weight: 700;
  }
  p, button {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }
}

.backToTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: -100px;
  bottom: 40px;
  transition: right 0.5s;
  font-size: 10vw;
  padding: 0px;
  border-radius: 100%;
  z-index: 999;
  background-color: #303030 !important; 
  font-size: 10vw !important;
  filter: drop-shadow(0, 0, 10px, #f5f5f5);
  &.backTopTra {
    right: 20px;
  }
  svg, img {
    height: auto;
    width: auto;
    fill: #F5F5F5 !important;
  }
}

/* ---------------------------- */

.events {
  .zoom {
    img {
      aspect-ratio: 8/5;
    }
  }
}

.liveEvents {
  color: #EEE;
  background-color: #303030;
  .live-events-details {
    color: #303030;
    transition: 0.5s all;
    .show-details-box {
      background-color: #F5F5F5;
      height: 100%;
      font-family: 'Roboto';
    }
    h4 {
      font-size: 20px;
      font-family: 'Roboto';
    }
    h6 {
      font-size: 14px;
    }
    .description {
      font-size: 15px;
    }
    img {
      min-height: 230px;
      height: 230px;
      object-fit: contain;
    }
    h3 {
      font-weight: 800;
    }
  }
}

/* ---------------------------- */

.rjc-banner {
  background: #212121;
  font-family: 'optima_nova_lt_regular';
  h2 {
    font-size: 1.8rem;
    line-height: 40px;
  }
  h3 {
    font-size: 1.4rem;
    line-height: 35px;
  }
  img {
    max-width: 600px;
    margin-right: 10px;
  }
}

.highlight {
  color: #d7b264;
  font-weight: 900  ;
}

/* ---------------------------- */

.originNAlliances {
  background-color: #303030;
  h2 {
    /* font-size: 5  vh; */
    letter-spacing: 4px;
    color: white;
  }
  .origin-details{
    gap: 50px;
    .origin-details-box {
      cursor: pointer;
      img {
        /* max-width: 150px; */
        max-width: 135px;
      }
      .primary-holds {
        max-width: 350px;
        width: 350px;
      }
    }
  }
}

.grading {
  background-color: #ebebeb;
  .grading-area {
    padding: 120px 0;
    color: #F5F5F5;
    background: url(../Assets/Image/common/bg-1.jpg) no-repeat center center / cover;
    position: relative;
    z-index: 8;
    h1 {
      font-size: 60px;
    }
    &::before {
      position: absolute;
      content: '';
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: #303030;
      opacity: 0.6;
      z-index: -1;
    }
    p {
      font-size: 22px;
    }
  }
  p {
    font-size: 18px;
  }
  .grading-detail-area {
    max-width: 1440px;
    margin: auto;
    color: #303030;
    .grading-details {
      border-bottom: solid 2px darkgray;
      padding-block: 25px 50px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      h2 {
        position: relative;
        margin-bottom: 25px;
        text-transform: capitalize;
        /* &::before {
          content: '';
          position: absolute;
          width: 75px;
          background-color: #313131d7;
          height: 1.5px;
          bottom: -8px;
        } */
      }
    }
  }
}

table {
  /* display: table-row-group;
  overflow-x: scroll; */
  tr {
    td,th {
      font-size: 20px;
      padding: 5px;
      font-weight: 700;
      font-family: 'Poppins', sans-serif;
      white-space: pre;
      padding-inline: 10px;
    }
  }
  .font-normal {
    tr, td {
      font-weight: 400;
    }
  }
}

 /* Base Header Styles */
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: auto;
  z-index: 999;
  transition: 0.5s;
  padding-block: 25px;
}

.fixed-header {
  background-color: #F5F5F5;
  padding: 8px 0;
  box-shadow: 0 0 10px #303030;
  .navLink {
    color: #212121;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #212121;
      transition: width 0.5s ease-in-out;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .arjivhead {
    filter: invert(1);
  }
  .toggle-menu {
    color: #303030;
  }
}
.dropdown-item {
  color: #212121;
}
.dropdown-menu {
  background-color: #FFF;
  color: #212121;
}

.banner {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background: url('../Assets/Image/common/bg-2.jpg') no-repeat center / cover;
  position: relative;
  color: #F5F5F5;
  .banner-details {
    
    margin-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    inset: 0 0 0  0;
    z-index: 99;
    h1 {
      font-size: 72px;
    }
  }
}

.imagesection {
  .image-box {
    background: #F5F5F5;
    h3 {
      position: relative;
      font-weight: 700;
      &::before {
        content: "";
        background: #303030;
        width: 75px;
        height: 2px;
        position: absolute;
        bottom: 3px;
      }
    }
  }
  .dark-area {
    background: #303030;
    p {
      margin-top: 15px;
      text-align: left;
      font-size: 20px;
    }
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-x: auto;
}

.mw-mc {
  max-width: max-content;
}
.w-mc {
  width: max-content !important;
}

.arjiv-blogs {
  text-align: left;
  color: #414143;

  .blog-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      &:hover {
        transform: translateY(-8px);
      }
    }

    img {
      min-height: 230px;
      max-height: 230px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .blog-box-details {
      background-color: #F5F5F5;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 12px;
    }

    h5 {
      font-family: 'optima_nova_lt_regular';
      font-size: 20px;
      color: #414143;
      font-weight: 600;
    }
    
    p {
      font-family: 'Poppins';
      letter-spacing: 0.2px;
      line-height: 20px;
      font-size: 14px;
      color: #414143;
    }
  }
}
/* -------------------------------------------- */
.arjiv-crumb {
  background-color: #303030;
  h1 {
    font-size: 5vh;
  }
  a {
    text-align: center;
    font-size: 20px;
    text-transform: capitalize;
    color: #303030;
  }
  .breadcrumb-item {
    align-items: center;
  }
  .active {
    font-size: 20px;
    color: #303030;
    text-transform: capitalize;
  }
}

.bg-arjiv {
  background-color: #f5f5f5;
  background: #F5F5F5 url('https://betaadmin.arjivexports.com/static/media/chatBg.fa007583.png') center/contain;
}
.arjiv-article {
  border: solid 1px #bebebe;
  max-width: 800px;
  margin: 0 auto;
  background-color: #d6d6d6d7;
  background-color: #f5f5f58e;
  background-color: #e9e9e98e;
  color: #414143;
  border-radius: 10px;
  box-shadow: 0 0 10px #b1b1b1;
  .article-header {
    border-bottom: solid 1px #D8D8D8;
    border-bottom: dashed 1px #bebebe;
    padding: 15px 0;
    text-align: start;
  }
  .article-content {
    text-align: start;
    h2 {
      font-family: 'optima_nova_lt_regular';
      font-weight: 600;
      display: list-item;
      margin-left: 30px;
      margin-bottom: 15px;
    }
    img {
      width: 100%;
      box-shadow: 0 0 10px #C8C8C8;
      margin-bottom: 15px;
      border-radius: 5px;
    }
    p, li {
      font-size: 18px;
      line-height: 1.4;
      text-align: justify;
      font-family: 'Poppins';
      color: #414143;
      border-bottom: dashed 1px #bebebe;
      padding-bottom: 15px;
      &:last-child {
        border-bottom: none;
      } 
    }
    /* Ordered List Styling */
    .blog-section-list {
      list-style-type: disc;
      padding-left: 15px;
    }

    .blog-section-list li {
      border: none;
      list-style-type: disc;
    }
  }
  h1 {
    font-family: 'optima_nova_lt_regular';
    font-weight: 600;
    font-size: 44px;
  }
}
/* -------------------------------------------- */



/* body {
  cursor: url(http://localhost:3000/static/media/G-H.a293656c.png) 17 17, pointer !important;
} */
/* Screen Size 1100 px */

@media (max-width: 1366px) and (min-width: 992px) {
  .originNAlliances {
    .origin-details{
      .origin-details-box {
        img {
          max-width: 100%;
        }
        .primary-holds {
          max-width: 200px;
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .navLink {
    font-size: 15px;
  }
}

/* Screen Size 992 px */

@media (max-width: 992px) {
   /* -------------------RESPONSIVE HEADER-------------------- */
  .toggle-menu {
    display: block;
    color: #FFF;
    width: 30px;
    height: 30px;
  }
  .header {
    display: block;
    padding-inline: 10px;
    .close-sidebar-btn {
      right: 3%;
      top: 3%;
      width: 30px;
      height: 30px;
    }
    .collapse .show .navbar-collapse {
      background-color: #F5F5F5;
    }
    .navbar-collapse {
      position: fixed;
      overflow: hidden;
      max-width: 320px;
      width: 100%;
      background: #F5F5F5;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-100%);
      transition: 0.5s;
      filter: drop-shadow(0px 0px 15px #303030);
      border-radius: 0 10px 10px 0;
      .navLink {
        color: #303030;
      }
    }
    .navbar-collapse.show {
      transform: translateX(0);
    }
    .fixed-header {
      position: fixed;
    }
  }
  .grading {
    p {
      font-size: 17px;
    }
  }
  .originNAlliances {
    .origin-details{
      .origin-details-box {
        img {
          max-width: 100%;
        }
      }
    }
  }
  /* -------------------RESPONSIVE HEADER-------------------- */
}

/* Screen Size 767 px */

@media (max-width: 768px) {

  .loginbtn {
    letter-spacing: 1px;
  }

  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 13px;
    font-size: 45px;
    line-height: 65px;
    font-weight: 600;
  }
  .logo img.img-fluid {
    width: 75px;
    position: absolute;
    left: 40px;
  }

  .logo-container {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }
  .copyright {
    height: 50px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .originNAlliances {
    .origin-details{
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      img {
        max-width: 130px;
      }
    }
  }
  .rjc-banner {
    text-align: center;
  }
  .grading {
    .grading-detail-area {
      .grading-details {
        padding-block: 15px;
        .base-grid {
          display: grid !important;
          grid-template-columns: repeat(5, 1fr);
          row-gap: 15px;
          img {
            max-width: 90px;
          }
        }
      }
    }
  }
}

/* Screen Size 576px */
@media (max-width: 576px) {
  .rjc-banner {

    h2,
    h3 {
      justify-content: center;
      font-size: 100%;
      justify-content: space-evenly;
      line-height: normal;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .imagesection {
    text-align: center;

    .image-box {
      &:nth-child(even) {
        background-color: #e9e9e9;
      }

      h3 {
        &::before {
          bottom: 3px;
          width: 50%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .originNAlliances {
    .origin-details {
      img {
        max-width: 100px;
      }
    }
  }
  .grading {
    .grading-detail-area {
      .grading-details {
        .base-grid {
          grid-template-columns: repeat(3, 1fr);
          img {
            max-width: 80pxcd;
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 11px;
    font-size: 40px;
    padding-left: 15px;
  }
  .logo img.img-fluid {
    width: 70px;
    position: absolute;
    left: 15px;
  }

  .logo-container {
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }

  .sicon {
    font-size: 22px;
  }

  .copyright {
    height: 45px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 12px;
    letter-spacing: 0.7px;
  }
  .footer-card {
    padding-top: 18px;
    padding-bottom: 0%;
    font-size: 20px;
    text-align: center;
  }
  .footer-text {
    padding-bottom: 17px;
    font-size: 14px;
    text-align: center;
  }
  .number {
    color: #303030 !important;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0px;
    word-spacing: 5px;
  }
  .grading {
    p {
      font-size: 15px;
    }
  }
}

/* Screen Size 400px */
@media (max-width: 375px) {
  .logo-container p h1 {
    text-transform: uppercase;
    letter-spacing: 9px;
    font-size: 35px;
    padding-left: 15px;
  }
  .logo img.img-fluid {
    width: 65px;
    position: absolute;
    left: 10px;
  }
  .logo-container {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    background: #F5F5F5;
    padding: 0 0;
  }

  .sicon {
    font-size: 16px;
  }

  .copyright {
    height: 42px;
    color: #cbdcd9 !important;
    text-align: center;
    font-weight: 300;
    display: block;
    padding-top: 0.95rem;
    background-color: #303030;
    font-size: 10px;
    letter-spacing: 0.7px;
  }
  .footer-card {
    padding-top: 16px;
    padding-bottom: 0%;
    font-size: 18px;
    text-align: center;
  }
  .footer-text {
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
  }
  .number {
    color: #303030 !important;
    font-size: 14px;
    box-sizing: border-box;
    padding: 0px;
    word-spacing: 4px;
  }
  .grading {
    p {
      font-size: 14px;
    }
    .grading-detail-area {
      .grading-details {
        .base-grid {
          grid-template-columns: repeat(2, 1fr);
          img {
            max-width: 50px;
          }
        }
      }
    }
  }
}
