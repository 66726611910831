@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  overflow-x: hidden !important;
}

.main-banners {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .tsparticles-canvas-el {
    filter: invert(1);
    position: absolute;
    inset: 0;
    z-index: 14;
    object-fit: contain;
  }
  .banner-details {
    z-index: 9;
    position: relative;
    height: 100vh;
    background: url(../Assets/Image/Arjiv/mine-source1.jpg) no-repeat 50% 80% fixed;
    .banner-details-content {
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: #000;
        opacity: 0.7;
      }
      img {
        object-fit: cover;
      }
      .banner-info {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
          font-size: 72px;
          font-size: 50px;
          /* font-size: 40px; */
          letter-spacing: 1px;
          text-transform: uppercase; 
          font-weight: 600;
          font-family: optima_nova_lt_regular, sans-serif;
          font-family: 'Poppins';
          font-family: "Montserrat", serif;
          font-weight: 700;
          margin-block: 40px;
        }
        p {
          font-size: 28px;
          font-size: 22px;
          letter-spacing: 1px;
          max-width: 800px;
          margin: 0 auto;
          /* font-family: 'Poppins'; */
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    
    li {
      list-style: none;
      margin: 0 5px;
      width: 14px;
      height: 14px;
      button {
        background-color: transparent;
        border: none;
        color: transparent;
        font-size: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: block;
        transition: background-color 0.3s ease-in-out;
  
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #bbb; 
        }
  
        &:hover:before {
          background-color: #F5F5F5; 
        }
      }
      &.slick-active {
        button {
          &:before {
            background-color: #FFF; 
          }
        }
      }
    }
  }
}

/* -------------------------------- */

/* M I L E S T O N E */

.milestone-container {
  max-width: 991px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  .milestone-header {
    text-align: left;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .milestone-wrapper {
    position: relative;
    padding: 80px 0 30px;
    border-left: 5px dashed #F5F5F5;
    border-spacing: 10px;
    .milestone-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
      position: relative;
      .milestone-year-box {
        position: sticky;
        top: 100px;
        .milestone-year {
          position: relative;
          top: 10px;
          left: -53px;
          bottom: 50px;
          height: 100px;
          width: 100px;
          background-color: #F5F5F5;
          color: #414143;
          font-size: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 3px #151515;
          img {
            width: 60px;
            height: 60px;
            aspect-ratio: 1;
            object-fit: contain;
          }
        }
      }
    }
    .milestone-content {
      margin-left: 20px;
      background-color: white;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      width: 100%;
      gap: 10px;
      .arjiv-evolution {
        gap: 10px;
      }
      h3 {
        font-size: 28px;
        color: #414143;
      }
      p {
        font-size: 18px;
        margin: 0;
        color: #414143;
      }
      img {
        max-width: 100px;
        max-height: 100px;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1;
      }
    }
  }
}

/* M I L E S T O N E */

/* -------------------------------- */


/* -------------------------------- */

/* G R I D B A N N E R */

.grid-banner {
  .main-content {
    background-color: #212121;
  }
  .grid-wrapper{
    height: calc(100vh - 100px);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .grid-box{
      /* background-color: yellow; */
      border-right: solid 2px #F5F5F5;
      align-content: start;
      filter: grayscale(1);
      transition: 0.5s;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* justify-content: space-around; */
      flex-direction: column;
      video {
        transition: 3s;
        height: 75vh;
        object-fit: cover;
      }
      .mask-box {
        height: 75vh;
        transition: 3s;
        height: 75vh;
        object-fit: cover;
        overflow: hidden;
      }
      h2 {
        margin-block: 50px;
        text-transform: uppercase;
      }
      &:hover {
        filter: grayscale(0);
        video {
          scale: 1.2;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        align-content: end;
      }
      &:last-child {
        border: none;
      }
    }
  }
}

/* G R I D B A N N E R */

.our-future{
  color: #F5F5F5;
}

.policy {
  p {
    font-family: 'Poppins', sans-serif;
    line-height: normal;
  }
}
.darken-bg {
  padding-block: 120px;
  z-index: 10;
  overflow: hidden;
  color: #F5F5F5;
  background: url(../Assets/Image/Arjiv/mine-source.jpg) no-repeat 50% 80% fixed; background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #000;
    opacity: 0.6;
    z-index: -1;
  }
  .testimonial-style-2 .testimonial-item .testimonial-content {
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;
    z-index: 99;
    position: relative;
  }
  p {
    font-size: 30px;
    .quote-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url(../Assets/Image/Icons/quote.svg) no-repeat center;
      background-size: contain;
    }
  }
  h6 {
    font-size: 18px;
  }

}


.customization {
  height: 100%;
  position: relative;
  .customization-details {
    position: absolute;
    inset: 0;
    color: #F5F5F5;
    p, button {
      font-size: 20px;
    }
    h4 {
      font-family: optima_nova_lt_regular, sans-serif;
      font-size: 2vmax;
      font-size: 4vmax;
      letter-spacing: 0.5px;
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 40PX;
      max-width: 530px;
      line-height: 32px;
      font-weight: normal;
      letter-spacing: 1px;
    }
    button {
      letter-spacing: 5PX;
    }
    h3 {
      font-size: 2.5vmax;
      font-family: "Roboto", sans-serif;  
      max-width: max-content;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 15px;
      letter-spacing: 2px;
      position: relative;
      display: inline-block;
      &::before {
        content: '';
        position: absolute;
        bottom: -30px;
        background: #F5F5F5;
        height: 2px;
        width: 200px;
        width: 150px;
        z-index: 1;
      }
    }
  }
}

.btn-light-dark {
  border-color: #303030;
  color: #303030;
  &:hover {
    background: #303030;
    color: #f5f5f5;
  }
}
/* 
.layout-match-pair {
  padding: 50px 20px;
  background-color: #f4f4f4;

  .pair-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .pair-item {
      display: flex;
      align-items: center;
      gap: 20px;

      &.reverse {
        flex-direction: row-reverse;
      }

      .pair-image {
        flex: 1;
        max-width: 50%;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      .pair-content {
        flex: 1;
        text-align: center;

        .pair-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #333;
        }

        .pair-description {
          font-size: 16px;
          line-height: 1.6;
          color: #555;
        }
      }
    }
  }
} */

.layout-matchPair {
  background-color: #151515;
  background-color: radial-gradient(circle, #626262 0%, #262626 100%);
  color: #F5F5F5;
  .main-row {
    max-width: 1260px;
  }
  .box-img {
    img {
      aspect-ratio: 1;
      max-height: 650px;
      object-fit: cover;
      /* box-shadow: 10px 10px 1px #F5F5F5; */
    }
    .reverse-img {
      margin-top: -75px;
      /* box-shadow: -10px -10px 1px #ffffff; */
    }
  }
  .box-details {
    position: relative;
    z-index: 9;
    &::before {
      content: '';
      position: absolute;
      inset: -15% 0 0 5%;
      width: 250px;
      height: 250px;
      background-color: #f5f5f51a;
      border-radius: 100%;
      z-index: -1;
      opacity: 1;
      animation: slideFigure 15s ease-in-out infinite;
    }
    img {
      filter: invert(1);
      margin-right: 15px;
    }
  }
  h3 {
    font-family: optima_nova_lt_regular, sans-serif;
    font-size: 2.5rem;
    line-height: normal;
    position: relative;
    font-weight: 700;
    &::before {
      content: "";
      background: #F5F5F5;
      width: 150px;
      height: 2px;
      position: absolute;
      bottom: 3px;
    }
  }
  p {
    font-size: 24px;
  }
}

.what-we-do {
  .process-content {
    padding: 75px 0;
    .process-box {
      border-bottom: dashed 2px #555555;
      padding-bottom: 50px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
      }
      video {
        max-height: 500px;
        aspect-ratio: 1;
        object-fit: cover;
      }
      video, img{
        box-shadow: 0 25px 50px -12px #181818;
      }
      .flip-mirror {
        transform: scaleX(-1);
      }      
      .process-title {
        font-size: 32px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: solid 2px white;
        max-width: max-content;
      }
      .process-description {
        font-size: 20px;
      }
    }
  }
}

.notfound {
  height: 75vh;
  h1 {
    font-size: 210px;
    letter-spacing: 10px;
    font-family: monospace, sans-serif;
    font-weight: 600;
  }
  h2 {

    font-size: 64px;
  }
}

@keyframes slideFigure {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(30px); 
  }
  100% {
    transform: translateX(0px);  
  }
}

/* -------------------------------- */


@media screen and (max-width: 1299px) {
  .customization {
    /* padding-bottom: 50px; */
    .customization-details {
      h4 {
        font-size: 2vmax
      }
      h3 {
        font-size: 2.5vmax;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .customization {
    /* padding-bottom: 100px; */
    .customization-details {
      position: unset;
    }
  }
  .grid-banner {
    .main-content {
      background-color: #212121;
    }
    .main-content {
      padding-top: 0 !important; 
    }
    .grid-wrapper{
      grid-template-columns: repeat(1, 1fr);
      height: 100vh;
      .grid-box{
        align-content: start;
        display: unset;
        video {
          height: 100%;
        }
        .mask-box {
          height: 100%;
        }
        h2 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-banner {
    .banner-details {
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .main-banners {
    /* height: 75vh; */
    .banner-details {
      .banner-details-content {
        .banner-info {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 22px;
            line-height: normal;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .customization {
    background-color: #F5F5F5;
    .customization-details {
      color: #212121;
      h4 {
        font-size: 22px
      }
      h3 {
        font-size: 24px;
        &::before {
          background: #303030;
          bottom: -21px;
        }
      }
      button {
        font-size: 16px;
        padding: 10px !important;
      }
      .btn-outline-light {
        border-color: #303030;
        color: #303030;
        &:hover {
          background: #303030;
          color: #f5f5f5;
        }
      }
    }
  }
  .milestone-container {
    padding: 20px 0;
    .milestone-wrapper {
      padding: 50px 0;
      margin-left: 40px;
      .milestone-content {
        margin-left: -20px;
        img {
          max-width: 80px;
          max-height: 80px;
          margin-top: 5px;
        }
      }
      .milestone-item {
        .milestone-year-box {
          .milestone-year {
            height: 80px;
            width: 80px;
            left: -42px;
          }
        }
      }
    }
  }
  .notfound {
    h1 {
      font-size: 52px;
    }
    h2 {
      font-size: 24px;
    }
  }
}