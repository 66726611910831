body {
  margin: 0;
  font-family: optima_nova_lt_regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "optima_nova_lt_regular";
  src: local("optima_nova_lt_regular"),
    url(./fonts/OptimanovaLTRegular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "FiraSans";
  src: local("FiraSans"), url(./fonts/FiraSans-Regular.ttf) format("truetype");
} */
