.body {
  background-color: #303030;
}

.sub {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}

.subImage {
  background-color: #303030;
}

/* .subitem {
  padding-top: 20px;
} */

.subTextpart {
  color: #303030;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 0; 
  opacity: 1;
}

.subhead {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}

.cardtext {
  background-color: #F5F5F5;
}

/* .subbutton {
  color: #F5F5F5;
  width: 98px;
  height: 45px;
  font-size: 15px;
  line-height: 30px;
} */

/* .bodyimage {
  width: 100%;
  height: 480px;
} */

.journey {
  background-color: #181818;
  color: #F5F5F5;
  text-align: center;
}

.contactdetalis {
  height: 200px;
  background-color: #F5F5F5;
}

.footer {
  padding-top: 50px;
  height: 210px;
  color: #303030 !important;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: center;
  /* letter-spacing: 0.5px; */
}
/* .sm-holder.footer-sm {
  justify-content: center;
  margin-top: 10px;
} */

/* .sm-holder {
  display: flex;
  align-items: center;
} */

.main-content {
  background-color: #303030;
  color: #f5f5f5;
  text-align: center;
  /* font-family: optima_nova_lt_regular, sans-serif; */
  font-family: 'Poppins', sans-serif;
  padding-top: 100px;
  /* margin-top: -100px */
}

.bg-secondary {
  background-color: #F5F5F5 !important;
  color: #303030;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
}

/* Zoom Effect */
.zoom {
  width: 99%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.zoom img {
  width: 100%;
  transition: 1s all ease-in-out;
  /* aspect-ratio: 2; */
  object-fit: cover;
}

.zoom:hover img {
  transform: scale(1.08);
}

.bgimage-handler {
  position: relative;
  text-align: center;
}

/* TODO */

.carousel-caption h3 {
  font-family: optima_nova_lt_regular, sans-serif;
  font-size: 3rem;
  animation-name: slidertext;
  animation-duration: 10s;
  position: relative;
  color: #F5F5F5;
  right: 0rem;
  animation-iteration-count: infinite;
}

@keyframes slidertext {
  from {
    right: 0rem;
    opacity: 0.3;
    font-size: 2rem;
  }
  to {
    right: 15rem;
    opacity: 1;
    font-size: 2rem;
  }
}

.bg-image-text {
  position: absolute;
  bottom: 80px;
  left: 150px;
  font-size: 20px;
  line-height: 45px;
  background-color: transparent;
  color: #F5F5F5;
  font-style: normal;
  border: 1px solid #F5F5F5;
  font-family: optima_nova_lt_regular, sans-serif;
}

.bgimage {
  max-width: 100%;
  height: auto;
  display: block;
  font-family: optima_nova_lt_regular, sans-serif;
}

.bgtext-handler {
  height: 100px;
  background-color: #F5F5F5;
  color: #303030;
  text-align: center;
  font-size: 18px;
  text-transform: unset;
  padding: 40px 0%;
}
.cust img {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}

.custtext {
  color: #303030;
  font-family: optima_nova_lt_regular, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.custtitle {
  margin-bottom: 15px;
  line-height: 40px;
  font-size: 30px;
  font-weight: 500;
  text-transform: unset;
  word-wrap: break-word;
}

.custtextbody {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  text-transform: unset;
}

.title {
  text-align: left;
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 25px;
  color: #F5F5F5;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 1px;
}

.title2 {
  text-align: left;
  margin: 12px 0;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  word-wrap: break-word;
}

.contacttext {
  text-align: left;
  letter-spacing: 1px;
  font-size: 15px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 25px;
}

.btn-secondary {
  background-color: #303030;
  color: #F5F5F5;
  text-transform: capitalize;
  font-size: 1.1rem;
}

.btn-secondary:hover {
  background-color: #F5F5F5;
  color: #303030;
}

/* .carousel-item {
  position: relative;
  display: none;
  -webkit-transition: 0.2s ease-in-out left;
  -moz-transition: 0.2s ease-in-out left;
  -o-transition: 0.2s ease-in-out left;
  transition: 0.1s ease-in-out left;
} */

/* .carousel-item {
  transition-duration: 0.1s !important;
} */

.journeryTitle {
  height: 375px;
  background-color: #303030;
  font-family: optima_nova_lt_regular, sans-serif;
  margin: 0%;
}

/* Shape Select */
ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="cb"] {
  display: none;
}

label {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 3px;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  /* 4s */
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 60px;
  width: 60px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  border: solid 1px;
}

label span {
  transition-duration: 0.2s;
  border: solid 1px;
  height: 200px !important;
}

/* :checked + label {
  border-color: #ddd;
} */

/* :checked + label::before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
} */

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 15px #333;
  z-index: -1;
}

:checked + label span {
  transform: scale(0.9);
  box-shadow: 0 0 15px #333;
  z-index: -1;
}

.shapeitem-other {
  height: 600px;
}

.form-control {
  border-radius: 0rem !important;
}

.excellence {
  font-size: 35px;
  font-weight: 400;
  font-family: optima_nova_lt_regular, sans-serif;
  text-align: center;
  letter-spacing: 1px;
  color: #303030;
  word-spacing: 5px;
  vertical-align: middle !important;
  padding-bottom: 10px;
  padding-top: 20px;
}

.nav-2 {
  background-color: #F5F5F5;
  height: 100px;
  vertical-align: middle !important;
}

.thejourneybutton {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.journeybutton {
  font-size: 20px;
  font-family: optima_nova_lt_regular, sans-serif;
  width: 204px;
  transition: width 0.4s;
  animation-name: journeybuttonanimation;
}

.journeybutton:hover {
  background-color: #F5F5F5;
  color: #181818;
  width: 230px;
  animation-name: journeybuttonanimation;
}

textarea {
  resize: none;
}

@media (max-width: 1024px) {
  .cardtext {
    background-color: #F5F5F5;
    padding: 0rem 0.5rem !important;
    padding-top: 1rem !important;
  }
  .subhead {
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (max-width: 992px) {
  .main-content {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 80px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 30px;
    font-weight: 400;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 4px;
    vertical-align: middle !important;
    padding-bottom: 8px;
    padding-top: 18px;
  }
  .sub {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
  }
  .cardtext {
    background-color: #F5F5F5;
    padding: 0rem 0.2rem !important;
    padding-top: 0rem !important;
  }
  .subhead {
    margin-top: 10px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.2;
  }

  .subbutton {
    color: #F5F5F5;
    width: 98px;
    height: 42px;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .subtextbody {
    font-size: 15px;
    margin: 0 !important;
  }

  .journeryTitle h3 span {
    font-size: 54px !important;
  }

  .journeryTitle p {
    color: #F5F5F5;
    line-height: 30px;
    font-size: 17px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .journeryTitle {
    height: auto;
  }
}

@media (max-width: 576px) {
  .main-content {
    padding-top: 50px;
  }
}

@media (max-width: 425px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 60px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 25px;
    font-weight: 400;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 4px;
    vertical-align: middle !important;
    padding-bottom: 5px;
    padding-top: 15px;
  }
  .sub {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .journeryTitle p {
    line-height: 27px !important;
    font-size: 15px !important;
    width: 90% !important;
  }

  .journeryTitle h6 {
    font-size: 24px !important;
  }
}

@media (max-width: 375px) {
  .nav-2 {
    background-color: #F5F5F5;
    height: 50px;
    vertical-align: middle !important;
  }
  .excellence {
    font-size: 20px;
    font-weight: 300;
    font-family: optima_nova_lt_regular, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: #303030;
    word-spacing: 2px;
    vertical-align: middle !important;
    padding-bottom: 3px;
    padding-top: 11px;
  }

  .sub {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
}
